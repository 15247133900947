@import "base";
@import "_includes/config";
@import "_includes/bootstrap";
@import "_includes/mixins";

.cv-forgot-password {
  padding-top: 90px;
  padding-bottom: 90px;

  @media only screen and (min-width: 641px) {
    padding: 90px 60px;
  }

  #forgot_password, #change_password {
    border: 1px solid #d3ced2;
    padding: 20px;
    margin: 2em 0;
    text-align: left;

    .mcw_form_name_row, .mcw_form_password_row {
      input {
        width: 100%;
      }
    }
  }
}

.gform_wrapper textarea.medium {
  height: 145px;
}

.gform_wrapper {
  margin: 0;
}

.gf_spacing {
  textarea {
    //margin-top: 20px;
  }
  input {
    //margin-top: 20px!important;
  }
}

#gform_1 {
  #field_1_6 .ginput_container_email {
    margin-bottom: 14px;
  }

  .gform_footer.top_label {
    margin: 3.65rem 0 0;
  }

  .ginput_container {
    margin-bottom: .5rem!important;
    margin-top: 0px;
  }

  textarea {
    margin-top: 0;
  }
}

.woocommerce-form-login {
  position: relative;
  border-radius: 0 5px 0 5px!important;

  .woocommerce-Button {
    background: #f7882f!important;
    color: #ffffff!important;
    text-transform: capitalize;
    font-weight: 600!important;

    &:hover {
      opacity: 0.8;
    }
  }
}

.woocommerce form .form-row {
  label.woocommerce-form__label-for-checkbox {
    display: inline;
    float: right;
    position: absolute;
    right: 20px;
  }

  input[type="checkbox"] {
    opacity: 0;
    display: none;
  }

  input[type="checkbox"] + span {
    &::before {
      font-family: simple-line-icons, sans-serif;
      font-size: 13px;
      content: '\E080';
      border-radius: 0 5px 0 5px;
      background: #e1e0e2;
      color: #e1e0e2;
      padding: 7px 8px;
      margin-right: 10px;
      margin-left: 0px;
    }
  }

  input[type="checkbox"]:checked + span {
    background: transparent !important;
    border: inherit;

    &::before {
      content: '\E080';
      background: #f7882f;
      color: #fff;
    }

    &::after {
      display: none;
    }
  }
}

  .gform_wrapper .top_label .gfield_label {
    display: block;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
    font-size: .9rem;
    padding-bottom: 5px;
    margin-top: 20px;
  }

  .ginput_container_radio {
    display: inline-block;
    label {
      padding: 0!important;
      text-align: left!important;
      border: transparent!important;
      margin-right: 20px!important;

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
      &::before {
        font-family: simple-line-icons, sans-serif;
        font-size: 16px;
        content: '\E080';
        border-radius: 0 5px 0 5px;
        background: #ffffff;
        color: #ffffff;
        padding: 6px 7px;
        margin-right: 20px;
        border: 2px solid #c0c0c0;
      }
    }

    input[type="radio"] {
      display: none!important;
    }

    input[type="radio"]:checked + label {
      background: transparent;
      border: inherit;
      font-weight: normal!important;

      &::before {
        content: '\E080';
        background: #f7882f;
        color: #fff;
        border: 2px solid #f7882f;
      }

      &::after {
        display: none;
      }
    }
  }

  #gform_wrapper_1 {
    textarea, input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), select,
    .woocommerce form .form-row input {
      font-size: .875rem;
      padding: 9.0px 15px !important
    }
  }

  .gform_wrapper .gfield_radio li label {
    margin: 5px 0;
  }

  .gform_wrapper .top_label li ul.gfield_radio {
    display: flex;
  }

  .gform_wrapper .gfield_required {
    color: #f7882f;
  }

  .gform_wrapper ul li.field_description_below div.ginput_container_radio {
    margin-top: 0!important;
  }

  .gform_wrapper textarea,
  .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
  .gform_wrapper select,
  .woocommerce form .form-row input{
    padding: 13.5px 15px!important;
    font-size: .875rem;
    width: 100%;
    border: 1px solid $color-grey;
    color: $body-color;
    outline: none;

    padding: 10.5px 15px!important;
    //width: 100%;
    //border: 1px solid #e1e0e2;
    //color: #515050;
    //outline: none;
    font-size: 18px;
    //border-radius: 0 5px 0 5px;
    //line-height: 2px;

    @include cvz_border_radius();

    &::placeholder {
      color: rgba($body-color, 0.7);
    }
  }

  @include media-breakpoint-up(sm) {
    .gform_wrapper .top_label li.gfield.gf_right_half {
      //padding-bottom: 30px;
    }
  }

  @media only screen and (min-width: 641px) {
    .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
      width: 100%;
    }

    .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input[type=submit] {
      margin: 0;
    }

    body .gform_wrapper ul li.gfield {
      margin-top: 0;
    }

    .gform_wrapper li.hidden_label input {
      margin-top: 0;
    }

    body .gform_wrapper .top_label div.ginput_container {
      margin-top: 0;

      margin-bottom: 1.25rem;
    }

    body .gform_wrapper .top_label li:last-child div.ginput_container {
      margin-bottom: 0;
    }

    .gform_wrapper .gform_footer {
      padding: 0 1rem 0 0;
      margin: 1rem 0 0 0;

      text-align: right;

    }
}